<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1100"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit Record</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-10">
        <div class="row w-100 pb-4">
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-select
              v-model="formData.default_order_type_id"
              label="Order type"
              item-text="text"
              item-value="index"
              :items="serverData.order_types"
              outlined
              dense
              :error-messages="default_order_type_idErrors"
              @input="$v.formData.default_order_type_id.$touch()"
              @blur="$v.formData.default_order_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-select
              v-model="formData.status"
              label="Status"
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              outlined
              dense
              :error-messages="statusErrors"
              @input="$v.formData.status.$touch()"
              @blur="$v.formData.status.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-75px">
            <v-text-field
              v-model="formData.store_name"
              label="Store name"
              clearable
              outlined
              dense
              :error-messages="store_nameErrors"
              @input="$v.formData.store_name.$touch()"
              @blur="$v.formData.store_name.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-75px">
            <v-text-field
              v-model="formData.store_reference"
              label="Store reference"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-75px">
            <v-text-field
              v-model="formData.store_url"
              label="Store url"
              clearable
              outlined
              dense
              :error-messages="store_urlErrors"
              @input="$v.formData.store_url.$touch()"
              @blur="$v.formData.store_url.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.store_prefix"
              label="Store prefix"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.prefix_replace"
              label="Prefix replace"
              clearable
              min="0"
              type="number"
              outlined
              dense
              @change="() => validateMinValue('prefix_replace', 0)"
              :error-messages="prefix_replaceErrors"
              @input="$v.formData.prefix_replace.$touch()"
              @blur="$v.formData.prefix_replace.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.order_reference_key"
              label="Order reference key"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-4 my-0 py-0">
            <v-autocomplete
              v-model="formData.automatic_inventory_update"
              label="Automatic inventory sync"
              :items="booleanYesNoValues"
              item-text="text"
              item-value="index"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-4 my-0 py-0">
            <v-select
              v-model="formData.consider_billing_as_shipping"
              label="Consider billing as shipping"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-4 my-0 py-0">
            <v-select
              v-model="formData.gift_card"
              label="Allow Gift Card Payment"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
              :error-messages="gift_cardErrors"
              @blur="$v.formData.gift_card.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 my-0 py-0">
            <v-select
              v-model="formData.mark_order_as_paid"
              label="Mark order as paid"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 my-0 py-0">
            <v-select
              v-model="formData.skip_order_status"
              label="Skip order status"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 my-0 py-0">
            <v-select
              v-model="formData.enable_sku_reg"
              label="SKU registration"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
              :error-messages="enableSkuRegErrors"
              @input="$v.formData.enable_sku_reg.$touch()"
              @blur="$v.formData.enable_sku_reg.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-select
              v-model="formData.custom_shipping_type"
              label="Custom shipping type available"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
              :error-messages="customShippingTypeErrors"
              @input="$v.formData.custom_shipping_type.$touch()"
              @blur="$v.formData.custom_shipping_type.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-select
              v-model="formData.ignore_bundle"
              label="Ignore bundle"
              item-text="text"
              item-value="index"
              :items="booleanYesNoValues"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.ip_address"
              label="IP Address"
              outlined
              dense
            />
          </div>
          <div
            class="col-12 text-center pt-0 pb-8"
            v-if="
              serverData.integration.platform_id == 2 ||
              serverData.integration.platform_id == 19
            "
          >
            <h3 class="pb-2">Credentials</h3>
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
        </div>

        <!--begin::Actions-->
        <div class="mb-2 mt-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  components: { VJsoneditor },
  validations: {
    formData: {
      default_order_type_id: { required },
      prefix_replace: { required },
      status: { required },
      enable_sku_reg: { required },
      custom_shipping_type: { required },
      store_name: { required },
      store_url: { required },
      gift_card: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      default_order_type_id: null,
      prefix_replace: null,
      store_prefix: null,
      order_reference_key: null,
      status: null,
      automatic_inventory_update: null,
      consider_billing_as_shipping: null,
      skip_order_status: null,
      enable_sku_reg: 0,
      custom_shipping_type: 0,
      ip_address: null,
      credentials: {},
      store_name: null,
      store_reference: null,
      store_url: null,
      ignore_bundle: 0,
      mark_order_as_paid: 0,
      gift_card: 0,
    },
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
  }),
  methods: {
    loadDataFromServer() {
      let sendData = { id: this.$store.getters.getItemForAction.id };
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/edit", sendData)
        .then((response) => {
          this.serverData = response.data;
          // this.resetEditForm();
          this.formData = { ...this.serverData.integration };
          this.formData.gift_card = Number(
            this.serverData.integration.gift_card
          );
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let sendData = this.formData;
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/update", sendData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Integration data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = !this.dialog;
          // this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      // if (this.serverData) {
      //   let copy = { ...this.serverData.integration };
      //   Object.entries(this.formData).forEach((localD) => {
      //     Object.entries(this.serverData.integration).forEach((serverD) => {
      //       if (localD[0] === serverD[0]) {
      //         this.formData[localD[0]] = copy[serverD[0]];
      //       }
      //     });
      //   });
      // } else {
      this.formData = {
        id: null,
        default_order_type_id: null,
        prefix_replace: null,
        store_prefix: null,
        order_reference_key: null,
        status: null,
        automatic_inventory_update: null,
        consider_billing_as_shipping: null,
        skip_order_status: null,
        enable_sku_reg: 0,
        custom_shipping_type: 0,
        ip_address: null,
        credentials: {},
        store_name: null,
        store_reference: null,
        store_url: null,
        ignore_bundle: 0,
        mark_order_as_paid: 0,
        gift_card: 0,
      };
      // }
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    booleanYesNoValues: function () {
      return [
        { text: "No", index: 0 },
        { text: "Yes", index: 1 },
      ];
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    default_order_type_idErrors: function () {
      return this.handleFormValidation("default_order_type_id");
    },
    prefix_replaceErrors: function () {
      return this.handleFormValidation("prefix_replace");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    customShippingTypeErrors: function () {
      return this.handleFormValidation("custom_shipping_type");
    },
    enableSkuRegErrors: function () {
      return this.handleFormValidation("enable_sku_reg");
    },
    store_nameErrors: function () {
      return this.handleFormValidation("store_name");
    },
    store_urlErrors: function () {
      return this.handleFormValidation("store_url");
    },
    gift_cardErrors: function () {
      return this.handleFormValidation("gift_card");
    },
  },
};
</script>
